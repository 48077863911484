import React, {useState} from "react";

function App() {
	const [response1, setResponse1] = useState(null);
	const [response2, setResponse2] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);
	const [error, setError] = useState(null);

	const handleRequest1 = async () => {
		try {
			const res = await fetch("https://your-backend-url-1.com/endpoint1");
			const data = await res.json();
			setResponse1(data);
			setError(null);
		} catch (err) {
			setError(`Error with URL 1: ${err.message}`);
		}
	};

	const handleRequest2 = async () => {
		try {
			const res = await fetch("https://your-backend-url-2.com/endpoint2");
			const data = await res.json();
			setResponse2(data);
			setError(null);
		} catch (err) {
			setError(`Error with URL 2: ${err.message}`);
		}
	};

	const handleRequest3 = async () => {
		try {
			// Assume the URL is something like: http://localhost:3000/?token=your-bearer-token-here
			const params = new URLSearchParams(window.location.search);
			const token = params.get("token"); // Extract the token from the URL parameters

			if (!token) {
				throw new Error("Token not found in URL parameters");
			}

			//const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwYXZlbEBzZXZlcnluLmN6IiwiaXNzIjoicmVrbGFtYWNlLmVsbmluby5jeiIsImV4cCI6MTcyNDE4OTQ2NiwiaWF0IjoxNzI0MTgyMjY2fQ.0efMqwx-tHC6HpzUp2XBc_bbFPehvefYQ-IKVowTHHuU770nkeCEbyq2vHc6PUgPY2U4Kvb3utZs8yx5NOlBdQ"; // Replace with your actual token
			const res = await fetch("https://backend.complaints.elsvc.net/file/S3/ticket/668d5c91cf6e9f591fd7287d/ident/86a30e3d-711f-43f5-8cac-932aaa4f5aaa", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!res.ok) {
				throw new Error("Network response was not ok");
			}

			const blob = await res.blob();
			const imageObjectURL = URL.createObjectURL(blob);
			setImageSrc(imageObjectURL);
			setError(null);
		} catch (err) {
			setError(`Error with URL 3 (Image): ${err.message}`);
		}
	};
	const handleRemoveImage = () => {
		setImageSrc(null);  // Clears the image source, removing the image
	};
	return (
		<div>
			<h1>CORS Test</h1>

			<button onClick={handleRequest1}>Request URL 1</button>
			{response1 && <pre>{JSON.stringify(response1, null, 2)}</pre>}

			<button onClick={handleRequest2}>Request URL 2</button>
			{response2 && <pre>{JSON.stringify(response2, null, 2)}</pre>}

			<button onClick={handleRequest3}>Request Image (URL 3)</button>
			{imageSrc && (
				<div>
					<button onClick={handleRemoveImage}>Remove Image</button>
					<img src={imageSrc} alt="Fetched from backend" style={{maxWidth: "100%", height: "auto"}}/>
				</div>
			)}

			{error && <p style={{color: "red"}}>{error}</p>}
		</div>
	);
}

export default App;
